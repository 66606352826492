import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .mtk7 { color: #FFCB6B; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk5 { color: #FF9CAC; }
  .material-theme .mtk4 { color: #546E7A; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df896916c5ea3fa63aec4e9979f9ec30/8ccd0/nestjs-with-typeorm-and-database-migrations-tutorial.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAbM3QDMZv//EABoQAAMAAwEAAAAAAAAAAAAAAAABAgMREiL/2gAIAQEAAQUCzNyLJTrWziW+JF5P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEREhAhMf/aAAgBAQAGPwJVEm8WjZwhH//EABoQAAEFAQAAAAAAAAAAAAAAAAEAESExQVH/2gAIAQEAAT8hFEU71bAbPFoJCLgB2qAhjG1BSOL/2gAMAwEAAgADAAAAEGg//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qqv/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxATLL//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFhkf/aAAgBAQABPxAkAMQlDw+x2q0EAvkmGQAHE0SIVtntcNDLYe2NPL4E/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "NestJS with TypeORM, automatic migrations and reused CLI configuration",
            "title": "NestJS with TypeORM, automatic migrations and reused CLI configuration",
            "src": "/static/df896916c5ea3fa63aec4e9979f9ec30/e5166/nestjs-with-typeorm-and-database-migrations-tutorial.jpg",
            "srcSet": ["/static/df896916c5ea3fa63aec4e9979f9ec30/f93b5/nestjs-with-typeorm-and-database-migrations-tutorial.jpg 300w", "/static/df896916c5ea3fa63aec4e9979f9ec30/b4294/nestjs-with-typeorm-and-database-migrations-tutorial.jpg 600w", "/static/df896916c5ea3fa63aec4e9979f9ec30/e5166/nestjs-with-typeorm-and-database-migrations-tutorial.jpg 1200w", "/static/df896916c5ea3fa63aec4e9979f9ec30/8ccd0/nestjs-with-typeorm-and-database-migrations-tutorial.jpg 1792w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here's a simple guide to setting up automated database migrations with TypeORM CLI in a NestJS project that I wish I had when I started.`}</p>
    <ul>
      <li parentName="ul">{`It uses a `}<inlineCode parentName="li">{`ormconfig.ts`}</inlineCode>{` file to configure the TypeORM CLI, which reuses the configuration from your application (no need to repeat yourself)`}</li>
      <li parentName="ul">{`It will automatically find your entities, no build step required (unless you're running or generating migrations)`}</li>
      <li parentName="ul">{`It will automatically bootstrap your database schema e.g. when developing locally`}</li>
      <li parentName="ul">{`Your table names will be in `}<inlineCode parentName="li">{`snake_case`}</inlineCode>{` and your TypeScript entities in `}<inlineCode parentName="li">{`camelCase`}</inlineCode></li>
      <li parentName="ul">{`It uses the `}<inlineCode parentName="li">{`ConfigModule`}</inlineCode>{` using the `}<a parentName="li" {...{
          "href": "https://docs.nestjs.com/techniques/configuration#configuration-namespaces"
        }}>{`Configuration namespaces approach`}</a></li>
      <li parentName="ul">{`It has package.json scripts to avoid having to specify the migrations directory every time you run a migration`}</li>
      <li parentName="ul">{`It provides a simple workflow for generating migrations based on entity changes and the initial migration`}</li>
    </ul>
    <p>{`See this `}<a parentName="p" {...{
        "href": "https://github.com/simplenotezy/nestjs-with-typeorm-and-migrations-example"
      }}>{`example repository for a demo`}</a>{`.`}</p>
    <h2>{`Setup`}</h2>
    <h3>{`Ensure you have the required dependencies`}</h3>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm install typeorm @nestjs/typeorm typeorm-naming-strategies @nestjs/config`}</span></span></span></code></pre>
    <p>{`And the dev dependencies:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm install cross-var ts-node --save-dev`}</span></span></span></code></pre>
    <p>{`Also be sure to add any database driver you need, e.g. `}<inlineCode parentName="p">{`pg`}</inlineCode>{` for PostgreSQL.`}</p>
    <h3>{`Add migration scripts to `}<inlineCode parentName="h3">{`package.json`}</inlineCode></h3>
    <p>{`It's a bit of some "magic" scripts to get the TypeORM CLI to work with your project setup `}<a parentName="p" {...{
        "href": "https://github.com/sramocki/nestjs-typeorm-example-migrations"
      }}>{`inspired by this repo`}</a>{`. Here's what you need to add to your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "diff",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`"scripts": {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  "typeorm": "ts-node -r tsconfig-paths/register ./node_modules/typeorm/cli -d ormconfig.ts",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  "migration:create": "cross-var ts-node -r tsconfig-paths/register ./node_modules/typeorm/cli migration:create ./src/database/migrations/$npm_config_name",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  "migration:generate": "cross-var npm run typeorm -- migration:generate ./src/database/migrations/$npm_config_name",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  "migration:run": "npm run build && npm run typeorm -- migration:run",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  "migration:revert": "npm run typeorm -- migration:revert"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></span></code></pre>
    <h3>{`Create your database migartion configuration file`}</h3>
    <p>{`Create the following file: `}<inlineCode parentName="p">{`src/database/database.config.ts`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`registerAs`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`@nestjs/config`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`TypeOrmModuleOptions`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`@nestjs/typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`SnakeNamingStrategy`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm-naming-strategies`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`default`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`registerAs`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`database`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`():`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`TypeOrmModuleOptions`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` (`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`cockroachdb`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` process`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`DATABASE_URL `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`||`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`postgresql://root:passwd@localhost:26257/defaultdb`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`autoLoadEntities`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`logging`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` process`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`NODE_ENV `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`===`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`development`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`||`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` process`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`DATABASE_QUERY_LOGGING `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`===`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`1`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// In production, you might want to run migrations differently`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// (e.g. not automatically on app start)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`migrationsRun`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`migrations`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` [`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`dist/src/database/migrations/*.js`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`namingStrategy`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`SnakeNamingStrategy`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Enable synchronize to auto-create tables, handy during initial development`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Use migrations to manage database changes thereafter`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// (once you have a production database / initial release ready)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// synchronize: process.env.NODE_ENV === 'development',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span></code></pre>
    <p>{`Adjust the connection URL and other options to match your database setup.`}</p>
    <h3>{`Import your database configuration in your `}<inlineCode parentName="h3">{`app.module.ts`}</inlineCode></h3>
    <p>{`If you're using the `}<inlineCode parentName="p">{`ConfigModule`}</inlineCode>{` with the `}<a parentName="p" {...{
        "href": "https://docs.nestjs.com/techniques/configuration#configuration-namespaces"
      }}>{`Configuration namespaces approach`}</a>{`, you can import your database configuration like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "diff",
      "data-index": "4"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`import databaseConfig from './config/database.config';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`@Module({`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  imports: [`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`    ConfigModule.forRoot({`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`      load: [databaseConfig],`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`    }),`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`    TypeOrmModule.forRoot(databaseConfig()),`}</span></span></span></code></pre>
    <h2>{`Create your `}<inlineCode parentName="h2">{`ormconfig.ts`}</inlineCode>{` file`}</h2>
    <p>{`This file is used to configure the TypeORM CLI which you'll be using to generate and run migrations.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "5"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ConfigModule`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`@nestjs/config`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` databaseConfig `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`src/config/database.config`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`DataSource`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ConfigModule`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`forRoot`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`isGlobal`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`load`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` [databaseConfig]`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`default`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`DataSource`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`...`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`databaseConfig`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`entities`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` [`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`src/**/*.entity.ts`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// This is the important part`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`any`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span></code></pre>
    <h2>{`The workflow`}</h2>
    <h3>{`Initial migration`}</h3>
    <p>{`This was the part that I found the most confusing. When you're bootstrapping a new project, you want to generate the initial migration based on the entities you've created.`}</p>
    <p>{`Simply run the following command to generate the initial migration:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "6"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run migration:generate --name=initial-db`}</span></span></span></code></pre>
    <h3>{`Migration from entity changes`}</h3>
    <p>{`Let's say you've changed a column name in your entity:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "diff",
      "data-index": "7"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`@Entity()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`export class User {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  @Column()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  name: string;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  fullName: string;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`You can now run the following command to generate a migration based on the changes you've made:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "8"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run migration:generate --name=change-user-column`}</span></span></span></code></pre>
    <p>{`It will generate a migration file in your `}<inlineCode parentName="p">{`src/database/migrations`}</inlineCode>{` directory, called something like `}<inlineCode parentName="p">{`1722856393104-change-user-column.ts`}</inlineCode>{`, that looks something like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "9"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`ChangedNameColumn1722859765490`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`implements`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ChangedNameColumn1722859765490`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`up`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`query`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ALTER TABLE "user" RENAME COLUMN "name" TO "full_name"`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`down`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`query`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ALTER TABLE "user" RENAME COLUMN "full_name" TO "name"`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\`\`\``}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`### Manually creating migrations`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`If you want to create a new migration manually, you can run the following command:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\`\`\``}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`bash`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`migration`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`create `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`--`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`my`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-new-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`migration`}</span></span></span></code></pre>
    <h2>{`Summary`}</h2>
    <p>{`I hope this guide helps you get started with setting up automated database migrations with TypeORM CLI in a NestJS project. It's a simple setup that I've found to be quite effective in my projects.`}</p>
    <p>{`Let me know if you have any questions or suggestions in the comments below!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      